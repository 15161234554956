import CInputDateRange from '@/components/CInputDateRange'
import CSelectSearch from '@/components/CSelectSearch'
import table from '@/mixin/table'
import { BtoF } from '@/services/MomentService'
import AccordionItem from '@/components/Page/AccordionItem'
import GCFilter from '@/components/GCFilter/index'
import { notifyByPlan } from '@/services/Notify/Toasts'
import Gate from '@/services/Gate/Gate'

export default {
  name: 'UserProjects',
  mixins: [table],
  inject: ['mountedComponent'],
  components: {
    CInputDateRange,
    CSelectSearch,
    AccordionItem,
    GCFilter,
  },
  data() {
    return {
      fields: [
        {
          key: 'project_name',
          label: 'Project Name',
          _style: '',
          link: {
            name: 'Project',
            params: { id: 'project_id' },
            iconNewTab: true,
          },
        },
        {
          key: 'project_number',
          label: 'Project Number',
          _style: '',
        },
        { key: 'owner_name', label: 'Owner Name', _style: '' },
        { key: 'stage', label: 'Stage', _style: '' },
        { key: 'estimated_start_date', label: 'Start Date', _style: '' },
        { key: 'estimated_end_date', label: 'End Date', _style: '' },
        {
          key: 'manager_name',
          label: 'Project Manager',
          _style: '',
          link: {
            name: 'CompanyUser',
            params: { id: 'company_id', user_id: 'manager_id' },
          },
        },
        {
          key: 'manager_phone',
          label: 'Manager Phone',
          _style: '',
          sorter: false,
        },
        {
          key: 'manager_email',
          label: 'Manager Email',
          _style: '',
          sorter: false,
          email: true,
        },
      ],
      projects: [],
      selectedTasks: [],
      editTasks: false,
      tempFilter: {},
      clearFilter: true,
    }
  },
  computed: {
    checkFilterBlock() {
      return !!Object.values(this.tempFilter).every(
        (element) => element === undefined,
      )
    },
  },
  methods: {
    async handleCreateProject() {
      (await Gate.can('create', 'project'))
        ? this.$router.push({ name: 'ProjectNew' })
        : notifyByPlan()
    },
    openInNewTab(id) {
      let routeData = this.$router.resolve({
        name: 'Project',
        params: { id: id },
      })
      window.open(routeData.href, '_blank')
    },
    madData(data) {
      return data.map((item) => {
        return {
          project_name: item.name,
          project_number: item.internal_number,
          owner_name: item.info.owner_name,
          owner_id: item.info.id,
          stage: item.stage,
          estimated_start_date: BtoF({ value: item.estimated_start_date }),
          estimated_end_date: BtoF({ value: item.estimated_end_date }),
          manager_name: item.manager.first_name + ' ' + item.manager.last_name,
          manager_phone: item.manager.phone,
          manager_email: item.manager.email,
          manager_id: item.manager_id,
          company_id: item.company_id,
          project_id: item.id,
        }
      })
    },
    makeQuery(f = {}) {
      f = Object.assign(this.tempFilter, f)
      return {
        ...f,
        per_page: this.perPage,
        page: this.activePage,
        sort_field: this.sort && this.sort.column,
        sort_direction: this.sort && this.sort.state,
        user_id: this.$route.params.user_id || '',
      }
    },
    fetchData(filter = {}) {
      this.key++
      this.loadingTable = true
      this.$http.users
        .getProjects(this.makeQuery(filter))
        .then((response) => {
          this.key++
          this.projects = this.madData(response.data.data)

          this.pages = response.data.meta.last_page
          if (this.activePage > this.pages) {
            this.activePage = this.activePage - 1
          }
        })
        .finally(() => (this.loadingTable = false))
    },
  },
}
