<template>
  <CCard>
    <teleport v-if="mountedComponent" to="#header-nav">
      <CBreadcrumbItem active>
        <span>Projects</span>
      </CBreadcrumbItem>
    </teleport>

    <CCardBody>
      <CAccordion :active-item-key="1">
        <AccordionItem :mobile-label="'Projects'" :item-key="1">
          <CCol class="GCFilterMaxWidth col-12 mb-3">
            <GCFilter
              component="Projects"
              :except-column="['company_name']"
              @search="(val) => onTimeoutSearch(() => fetchData(val))"
            />
          </CCol>

          <SmartTable
            :items="projects"
            :items-per-page="parseInt(perPage)"
            :columns="fields"
            :sorter-value="sort"
            :loading="loadingTable"
            :active-page="activePage"
            :per-page="perPage"
            :pages="pages"
            :clear-text="tempFilter.search ? 'No result found' : ''"
            @sorter-change="onSort"
            @update-active-page="(value) => (activePage = value)"
            @update-per-page="(value) => (perPage = value)"
          >
            <template #clear_text>
              <div
                class="table__clear d-flex align-items-center justify-content-center mb-2 w-100"
              >
                <template v-if="!checkFilterBlock">
                  <CIcon
                    class="table__clear__icon"
                    size="6xl"
                    name="cilClipboard"
                  />
                  <div class="table__clear__title">No result found</div>
                </template>
                <template v-else>
                  <CIcon
                    class="table__clear__icon"
                    size="6xl"
                    name="cilClipboard"
                  />
                  <div class="table__clear__title">No data yet</div>
                  <div class="table__clear__title-sub">
                    Add projects you have created will be displayed here.
                  </div>
                  <CButton
                    color="primary"
                    class="table__clear__btn"
                    @click="handleCreateProject"
                  >
                    Create a project
                  </CButton>
                </template>
              </div>
            </template>
          </SmartTable>
        </AccordionItem>
      </CAccordion>
    </CCardBody>
  </CCard>
</template>

<script src="./script.js"></script>
<style lang="scss">
@import 'style';
</style>

<style lang="scss" scope>
.table__clear {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 30px 60px;
  background: #f5f7f9;

  &__icon {
    color: #b1b7c1;
    margin-bottom: 20px;
  }

  &__title {
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 18px;
    color: #303c54;

    &-sub {
      margin-bottom: 35px;
      font-weight: 400;
      font-size: 14px;
      color: #465967;
    }
  }
}
</style>
